

$('#primary-navigation #fly-out-login').on('click', function() {
    $(this).siblings('.login-fly-out').toggleClass('is-active');
});

$(document).click(function(event) {
    if (!$(event.target).closest("#fly-out-login, .login-fly-out").length) {
        $("#primary-navigation").find(".login-fly-out").removeClass("is-active");
    }
});


