jQuery('#fly-out-login').bind('click',function(){


    /*var element = jQuery(this).children('.login-fly-out');


    if(element.hasClass('active')){

        element.fadeOut();
        element.removeClass('active');

    } else {

        element.fadeIn();
        element.addClass('active');

    }*/

});